export default function (siteKey) {
  return `
<h2 class="h1 font-weight-bold mt-4 text-dark pb-3">
    Login to your account
</h2>
<form>

    <div class="form-group">
        <label for="exampleInputEmail1">Username</label>
        <input type="text" placeholder="useremail@company.com" required="" class="form-control txtUserName">
    </div>

    <div class="form-group">
        <label for="exampleInputEmail1">Password</label>
        <div class="input-group mb-2">
            <input type="password" id="password-field" required class="form-control txtPassword">
            <div class="input-group-append">
            <!-- QPC-8590 default  eye icon should be closed-->
                <span toggle="#password-field" class="input-group-text fa fas fa-eye-slash eye-icon toggle-password"></span>
            </div>
            
        </div>
        <div class="text-secondary">This site is protected by reCAPTCHA and the Google
    	    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
  		</div>
        <div class="pt-2 d-none NotValid text-danger leto" style="font-size:13px;">
                    <i class='fa fa-exclamation-circle danger' style='font-size:13px;'></i>&nbsp<span
                      class="printError"></span>
                  </div>
        <div class="captchaDiv mt-2">
        </div>
        <p class="captchaError text-danger"> </p>
    </div>
    <div class="form-group mb-1"> 
    <div class="custom-control custom-checkbox col-6"><input type="checkbox" id="customCheck"
                    class="custom-control-input"><label for="customCheck"
                    class="custom-control-label font-size-13">Remember me
                </label></div>
    </div>
    <div class="form-group">
        <div class="d-flex">
            <div class="col-6 p-0"><a href="?page=ForgotPassword" class="font-size-13">Forgot your password?
                </a></div>
                <div class="col-6 p-0 text-right"> <a href="?page=ForgotUsername" class="font-size-13">Forgot your username?</a> </div>
        </div>
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-primary btn-lg btn-block btn-login btnLogin g-recaptcha" data-sitekey=${siteKey}>
            Log In
        </button>
    </div>
    <div class="small leto" style="color: #6c757d;">
        By logging in, you confirm that you agree to the <a href="/Optimiser-Master-Subscription-Agreement.pdf" target="_blank">Master Subscription Agreement</a> and <a href="https://docs.optimiser.com/projects/policies/privacy-policy" target="_blank">Privacy Policy</a>.
    </div>
    <hr>

    <div class="col-md-12 text-center mt-4">
        <span class="font-size-14 mr-2">Not a Customer?</span>
        <a href="https://www.optimiser.com/free-crm" target="_blank" class="btn btn-outline-primary ml-2 w-40 px-4">Try For Free</a>
    </div>
</form>` 
}

const EXTN_APP_LOGIN = function (siteKey) {
  return `<section class="msp-contact pt-4 pb-4">
    <div class="container">
      <div class="row">

        <section class="col-md-12 text-center">

          <div class="d-inline-block mb-5 pb-5">
            <div class="login bg-white mt-3 border-0 p-3">
              <form>
                <div class="row">
                  <div class="col-12 col-md-12 mt-1 pb-4 text-center ">
                    <img src="/images/MainLogo.png">
                  </div>
                  <div class="col-12 col-md-12 mt-1 text-left ">
                    <label class="text-primary">Username</label>
                    <!---QPC-8203: changed class--->
                    <input type="text" class="form-control txtUserName">
                  </div>

                  <div class="col-12 col-md-12 mt-3 text-left ">
                    <label class="text-primary">Password</label>
                    <input type="password" class="form-control txtPassword ">
                    <div class="pt-2 d-none NotValid text-danger" style="font-size:13px;">
                      <i class='fas fa-exclamation-circle danger' style='font-size:13px;'></i>&nbsp;<span
                        class="printError"></span>
                    </div>
                  </div>
                  <div class="col-12 col-md-12 mt-4 text-center ">
                    <a href="#" class="btn btn-blue-dark my-2 my-sm-0 pl-3 pr-3 btn-block btnLogin">Log In</a>
                  </div>
                  <div class="col-12 col-md-12 mt-4 text-center ">
                    <span>New Customer? Please contact our sales agent at support@optimiser.com or</span><br>
                    <a href="https://www.optimiser.com/free-crm" target="_blank"
                      class="btn btn-blue-dark my-1 my-sm-0 pl-3 pr-3 btnFreeCRM">Try for Free</a><br>
                    <span>Integrate Outlook with Optimiser to sync Contacts, Emails and Calendar between two
                      applications. This will allow your sales team to spend less time entering data in two different
                      applications and allow sales team to focus on SALES!</span>
                  </div>
                  <div class="mt-4" style="color: #6c757d;">
                    By logging in, you confirm that you agree to the <a href="/Optimiser-Master-Subscription-Agreement.pdf" target="_blank">Master Subscription Agreement</a> and <a href="https://docs.optimiser.com/projects/policies/privacy-policy" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <button type="button" class="otpPopupBtn d-none" data-toggle="modal" data-target="#otpPopup"></button>
  <div class="modal fade otpScreen" data-backdrop="static" id="otpPopup" tabindex="-1" role="dialog" aria-labelledby="otpPopupLabel"  aria-hidden="true">
    <div class="modal-dialog modalDialog" role="document">
      <div class="modal-content modal-md border-0">
        <div class="modal-body p-4">
          <div class="h3 font-weight-bold text-secondary">Confirm Your One-Time Password</div>
          <div class="h6 mb-4 emailSent font-weight-normal"></div>
          <input class="submittedOtp form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="6" placeholder="Enter OTP" type="number" name="otp"> 
          <p class="message text-danger mt-2"> </p>
          <div class="h6 mb-4 font-weight-normal">Didn't receive the OTP? Click <a href="#" class="text-outline-secondary font-weight-bold resentOtp">here</a> to resend.</div>
          <div class="mb-2">
            <button type="button" class="btnSubmitOtp btn btn-secondary ">Submit OTP</button>
            <button type="button" class="btnClose btn btn-outline-secondary  ml-2" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>`;
}

const OTP_FORM = function (email) {
    return `<button type="button" class="otpPopupBtn d-none" data-toggle="modal" data-target="#otpPopup">
    </button>
    <div class="modal fade otpScreen" data-backdrop="static" data-keyboard="false" id="otpPopup" tabindex="-1" role="dialog" aria-labelledby="otpPopupLabel"  aria-hidden="true">
        <div class="modal-dialog modalDialog" role="document">
        <div class="modal-content modal-md">
          <div class="modal-body p-4">
          <div class="h2 font-weight-bold text-primary">Confirm Your One-Time Password</div>
          <div class="h5 mb-4 emailSent">A One-Time Password has been sent to - ${email} </div>
          <input class="submittedOtp form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="6" placeholder="Enter OTP" type="number" name="otp"> 
          <p class="message text-danger mt-2"> </p>
          <div class="h5 mb-4">Didn't receive the OTP? Click <a href="#" class="text-primary font-weight-bold resentOtp">here</a> to resend.</div>
          <div class="mb-2">
            <button type="button" class="btnSubmitOtp btn btn-primary btn-lg">Submit OTP</button>
            <button type="button" class="btnClose btn btn-secondary btn-lg ml-2" data-dismiss="modal">Cancel</button>
          </div>
          </div>
        </div>
      </div>
    </div>`
}

const FORGOT_USERNAME = function (siteKey) {
  return `<h2 class="h1 font-weight-bold mt-4 text-dark pb-3">
Forgot Username
</h2>
<form onSubmit="return false;">
<div class="pb-3">
<p class="text-secondary">
  To recover your username, enter your <span class="text-primary">Optimiser's</span> registered
  email address.
</p>
<p class="text-danger link-expired d-none">The link to reset your password has expired. Please try again.</p>
</div>
<div class="form-group">
  <label for="exampleInputEmail1">Email</label>
  <input type="email" placeholder="useremail@company.com" required="" class="form-control mb-2 emailForRecover">
  <div class="text-secondary">This site is protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply. 
  </div>
  <p class="text-danger login-error pt-2"> </p>
  <div class="captchaDiv">
  </div>
  <p class="captchaError text-danger"> </p> 
</div>

<div class="form-group">
  <button type="button" class="btn btn-primary btn-lg btn-block g-recaptcha recoverUsernameBtn" data-sitekey=${siteKey} >
    Recover Username
  </button>
</div>
<hr>

<div class="col-md-12 text-center mt-4"><span class="font-size-14 mr-2">Need help with Login?
                    </span><a href="https://www.optimiser.com/contact-us" target="_blank"
                        class="btn btn-outline-primary ml-2 w-40 px-4">Contact Us
                    </a></div>
</form>`
}
const SVG_CAPTCHA = function (image) {
  return `<div class="col-auto captchaImage" >${image}
  </div> <a href="#" class="refreshCaptcha d-flex justify-content-center align-items-center p-2"> <i class="fas fa-sync-alt"></i></a>
  <div class="col inputCaptcha"><input class="captchaValue form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="6" placeholder="Enter Captcha" type="text" name="captcha"> </div>`
}
export {
  EXTN_APP_LOGIN,
  OTP_FORM,
  FORGOT_USERNAME,
  SVG_CAPTCHA
}